@import '../../../theme/app/settings/baseColors';

#cc-main {
  /** Change font **/
  //--cc-font-family: Roboto;

  /** Change button primary color to black **/
  --cc-btn-primary-bg: #BF1342;
  --cc-btn-primary-border-color: none;
  --cc-btn-primary-hover-bg: #99113B;
  //--cc-btn-primary-hover-bg: $first-theme-color-darker;
  --cc-btn-primary-hover-border-color: none;

  /** Also make toggles the same color as the button **/
  --cc-toggle-on-bg: var(--cc-btn-primary-bg);

  /** Make the buttons a bit rounder **/
  //--cc-btn-border-radius: 10px;
}